<script setup>
import Sidenav from "@/layout/Sidenav";
import Navbar from "@/layout/Navbars/Navbar.vue";
import AppFooter from "@/layout/Footer.vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import store from "@/store";
import PullRefresh from 'pull-refresh-vue3';
import {ref} from "vue";

const route = useRoute();
useStore();

const loading = ref(false);
const onRefresh = () => {
  location.reload();
};

</script>
<template>
  <pull-refresh v-model="loading" @refresh="onRefresh" pulling-text="Tira già per aggiornare..." loosing-text="Rilascia per aggiornare..." loading-text="Aggiornamento in corso...">

    <!-- top navigation bar -->
    <navbar v-if="route.name !== 'login' && route.name !== 'forgot-password' && route.name !== 'reset-password'"
            @click.stop.prevent="store.state.showSidenav = false"/>
    <!-- top navigation bar -->

    <!-- offcanvas -->
    <sidenav/>
    <!-- offcanvas -->

    <div class="container-fluid" @click.stop.prevent="store.state.showSidenav = false">
      <div class="row">
        <main class="ml-sm-auto px-md-4 py-4">
          <router-view/>
          <app-footer/>
        </main>
      </div>
    </div>

    <div class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
  </pull-refresh>
</template>
