import axios from 'axios'
import store from '@/store'
import process from "process";

// Creazione dell'istanza di axios
const service = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // timeout
})

// interceptor per aggiungere l'header
service.interceptors.request.use(
    config => {
        // Eseguito prima che la richiesta venga inviata
        if (store.getters.token) {
            config.headers['Authorization'] = `Bearer ` + store.getters.token.token
            config.headers['accept'] = 'application/json'
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        return response.data
    },
    error => {
        let customMessage;
        switch (error?.response?.status ?? 0) {
            case 401:
                customMessage = (typeof error.response.data.message === 'string') ? error.response.data.message : 'Errore';
                store.dispatch('user/logout');
                break;
            case 404:
                customMessage = "Risorsa non trovata";
                break;
            case 500:
                customMessage = "Non è stato possibile completare l'azione, riprova più tardi";
                break;
            case 503:
                customMessage = "E' in corso un aggiornamento del sistema, riprova più tardi";
                break;
            case 422:
                // Gestione errori di validazione
                if (error.response.data.message) {
                    customMessage = error.response.data.message;
                } else if (error.response.data.errors) {
                    // Prende il primo messaggio di errore disponibile
                    const firstError = Object.values(error.response.data.errors)[0];
                    customMessage = Array.isArray(firstError) ? firstError[0] : firstError;
                } else {
                    customMessage = 'Errore di validazione';
                }
                break;
            case 400:
                customMessage = error.response.data.message || 'Errore nella richiesta';
                break;
            default:
                customMessage = (error && error.message && typeof error.message === 'string') ? error.message : 'Errore';


        }

        if (customMessage === 'Network Error') {
            customMessage = 'Errore di rete, controlla la tua connessione'
        }
        return Promise.reject(customMessage)
    }
)

export default service